import React from "react";
import css from './OtherListings.module.css'
import { NamedLink } from "../../components";
import { FormattedMessage } from "react-intl";
import ListingCardProduct from "../../components/ListingCardProduct/ListingCardProduct";

const OtherListings = props => {
    const { listings, authorDisplayName, currentUser } = props;

    return (
        <div className={css.root}>
            <p className={css.title}>
                <strong>
                    {authorDisplayName}
                </strong>
                <span>
                    {` `}Listings
                </span>
            </p>

            <div className={css.listingsContainer}>
                {listings?.map((listing, index) => (
                    <ListingCardProduct
                        className={css.listingCard}
                        key={index}
                        listing={listing}
                        currentUser={currentUser}
                    />
                ))}
            </div>

            <NamedLink className={css.link}
                name="SearchPage"
                to={{ search: '?pub_brandId=' + '' }}>
                <FormattedMessage id="OtherListings.seeMore" />
            </NamedLink>
        </div >
    );
};

export default OtherListings;