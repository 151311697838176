import React from 'react';
import ListingImageGallery from './ListingImageGallery/ListingImageGallery';

import css from './ListingPage.module.css';
import { AddToFavoritesButton } from '../../components';

const SectionGallery = props => {
  const { listing, currentUser, variantPrefix } = props;
  const images = listing.images;
  const imageVariants = ['scaled-small', 'scaled-medium', 'scaled-large', 'scaled-xlarge'];

  const isOwnListing = listing?.author?.id?.uuid === currentUser?.id?.uuid;

  const thumbnailVariants = [variantPrefix, `${variantPrefix}-2x`, `${variantPrefix}-4x`];

  return (
    <section className={css.productGallery} data-testid="carousel">
      <ListingImageGallery
        images={images}
        imageVariants={imageVariants}
        thumbnailVariants={imageVariants}
      />
      <AddToFavoritesButton
        listingId={listing.id.uuid}
        currentUser={currentUser}
        isOwnListing={isOwnListing}
      />
    </section>
  );
};

export default SectionGallery;
